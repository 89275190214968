html {
    line-height: 1.15;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%
}

body {
    margin: 0
}

article,
aside,
footer,
header,
nav,
section {
    display: block
}

h1 {
    font-size: 2em;
    margin: .67em 0
}

figcaption,
figure,
main {
    display: block
}

figure {
    margin: 1em 40px
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible
}

pre {
    font-family: monospace, monospace;
    font-size: 1em
}

a {
    background-color: transparent;
    -webkit-text-decoration-skip: objects
}

abbr[title] {
    border-bottom: none;
    text-decoration: underline;
    text-decoration: underline dotted
}

b,
strong {
    font-weight: inherit
}

b,
strong {
    font-weight: bolder
}

code,
kbd,
samp {
    font-family: monospace, monospace;
    font-size: 1em
}

dfn {
    font-style: italic
}

mark {
    background-color: #ff0;
    color: #000
}

small {
    font-size: 80%
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline
}

sub {
    bottom: -.25em
}

sup {
    top: -.5em
}

audio,
video {
    display: inline-block
}

audio:not([controls]) {
    display: none;
    height: 0
}

img {
    border-style: none
}

svg:not(:root) {
    overflow: hidden
}

button,
input,
optgroup,
select,
textarea {
    font-family: sans-serif;
    font-size: 100%;
    line-height: 1.15;
    margin: 0
}

button,
input {
    overflow: visible
}

button,
select {
    text-transform: none
}

button,
html [type=button],
[type=reset],
[type=submit] {
    -webkit-appearance: button
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
    border-style: none;
    padding: 0
}

button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
    outline: 1px dotted ButtonText
}

fieldset {
    padding: .35em .75em .625em
}

legend {
    box-sizing: border-box;
    color: inherit;
    display: table;
    max-width: 100%;
    padding: 0;
    white-space: normal
}

progress {
    display: inline-block;
    vertical-align: baseline
}

textarea {
    overflow: auto
}

[type=checkbox],
[type=radio] {
    box-sizing: border-box;
    padding: 0
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
    height: auto
}

[type=search] {
    -webkit-appearance: textfield;
    outline-offset: -2px
}

[type=search]::-webkit-search-cancel-button,
[type=search]::-webkit-search-decoration {
    -webkit-appearance: none
}

::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit
}

details,
menu {
    display: block
}

summary {
    display: list-item
}

canvas {
    display: inline-block
}

template {
    display: none
}

[hidden] {
    display: none
}

.clearfix:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: '';
    clear: both;
    height: 0;
}

html {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    white-space: nowrap;
    clip-path: inset(100%);
    clip: rect(0 0 0 0);
    overflow: hidden;
}

body {
    margin: 0 auto;
    max-width: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    height: 100vh;
}

h1 {
    font-size: 36px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

img {
    display: block;
    width: 100%;
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';
}


/************************** welcome.js ***************************/
.welcomePage {
    background-image: url(./images/background2.png);
    background-size: cover;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
}

.logoTitle {
    margin-top: 100px;
}

.toTitle {
    margin: 25px 0;
}

.getStarted {
    margin-top: auto;
    padding: 0 20px 20px;
    width: 100%;
}

/* *******************************NameInput.js*************************** */

.nameInputContainer{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.nameTitleContainer{
    width: 80%;
    align-self: center;
}

.badge {
    font-size: 90px;
}

.backArrow{
    align-self: start;
    padding-left: 20px;
    margin-bottom: 30px;
}

.nameInput {
    margin-bottom: 20px;
}

.startPlanning {
    width: 100%;
}

/* *********************Header.js***************** */
.loopLogo{
    width: 20%;
    align-self: center;
}

.date {
    display: flex;
    flex-direction: column;
}

.dayOfWeek {
    margin-bottom: 0;
    padding-left: 17px;
}

.month {
    margin-top: 0;
    padding-left: 17px;
}

/* **************************Home.js ****************/

.home {
    background-image: url(./images/background1.png);
    background-size: cover;
    height: 100vh;
    position: relative;
}

.homeMenuIcon{
    margin: 10px 0 0 10px;
}

.dayOfWeek {
    margin-bottom: 0;
    padding-left: 17px;
}

.month {
    margin-top: 0;
    padding-left: 17px;
}

.addButton {
    position: fixed !important;
    bottom: 16px !important;
    right: 16px !important;
    z-index: 1000 !important;
}

.sideMenu {
    color: #1D1B20;
    padding: 10px 0;
}

.sideMenuIcon {
    color: #636B74;
    margin-right: 10px;
}

.sideMenuContainer{
    margin-top: 10px;
}

/* ***************TaskContainer.js*************** */

.taskContainer {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.taskContainer>p {
    color: #965835;
}

.habitContainer {
    color: #6F797A;
    padding-left: 17px;
}

/* ****************AddTaskMenu.js*************************** */
.addTaskTitle,
.addHabitTitle {
    text-align: center;
    margin-top: 40px;
}

.createTaskPlus{
    margin-right: 15px;
}

.createPlusTitle {
    display: flex;
    align-items: center;
}

.linkComponent {
    text-decoration: none;
}

.blueCircle {
    color: blue;
}

.greenCircle,
.greenFlag {
    color: green
}

.orangeCircle,
.orangeFlag {
    color: orange
}

.purpleCircle {
    color: purple
}

.redCircle,
.redFlag {
    color: red;
}

/* *****************TaskCreator.js********************* */
.taskCreator {
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.creatorTitle {
    text-align: center;
    margin-top: 5px;
}

.taskInput {
    width: 100%;
    margin-bottom: 20px;
    background-color: rgba(251, 252, 254, 1);
    border: none;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
}

.inputLabel {
    background-color: rgba(251, 252, 254, 1);
}

.taskCreator button {
    width: 100%;
    border: none;
    border-radius: 12px;
    cursor: pointer;
}

.taskCreator .MuiButton-root {
    align-self: end;
    width: auto;
}

.addTaskButtonCreator,
.addHabitButtonCreator{
    padding: 20px;
    background-color: #0B6BCB;
    color: white;
}

.errorInput {
    border: 1px solid red;
}

.errorText {
    color: red;
    padding: 0;
    margin-top: -15px;
}

/* ***************************HabitCreator.js***************************** */
fieldset {
    display: flex;
    justify-content: space-evenly;
    border: none;
}

.required{
    color: red;
}

/* ***********************ToDoCreator.js */

.toDoCreator {
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.toDoCreator .MuiButton-root {
    align-self: end;
    width: auto;
}

.MuiFormControl-root {
    width: 100%;
}

.toDoHeader {
    text-align: center;
}

.addToDo {
    width: 100%;
    margin-top: 20px;
    border: none;
    border-radius: 12px;
    cursor: pointer;
}

.toDoInput {
    width: 100%;
    margin-bottom: 20px;
    background-color: rgba(251, 252, 254, 1);
    border: none;
    border-radius: 12px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}

/* ************************Task.js***************************** */
ul {
    padding: 0px;
}

li {
    list-style: none;
}

.task {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 22px 15px;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 4px 10px #00000040;
    border-radius: 12px;
    transition: opacity 0.3s ease-in-out
}

.completed {
    background-color: grey;
    opacity: 0.6;
}

.categoryColor {
    padding-left: 10px;
}

.taskCardTitle h3 {
    margin-bottom: 0px;
}

.taskTitleInfoDot {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: left;
}

.taskTitleAndInfo {
    padding-left: 15px;
}

.taskInfo {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    text-align: center;
    padding-bottom: 10px;
}

.taskInfo p,
.taskInfo span {
    margin: 0;
    text-align: center;
}

.seperation {
    font-size: 8px;
    padding: 10px;
}

.fa-stopwatch {
    padding-right: 5px;
}

/* ******************ToDo.js************************** */
.redFlag,
.orangeFlag,
.greenFlag {
    display: inline;
}

.toDoContainer {
    display: flex;
    flex-direction: column;
    margin-left: 19px;
}

.priorityColor {
    display: flex;
    padding-bottom: 10px;
}
.priorityColor p {
    margin: 0;
}

.notes p {
    margin: 5px 0;
}

@media (max-width: 575px) {
    /* styles for extra small screens */
}

@media (max-width: 767px) {
    /* styles for small screens */
}

@media (max-width: 991px) {
    /* styles for medium screens */
}

@media (max-width: 1199px) {

}
